import {CommonModule} from '@angular/common';
import {Component, ElementRef, OnInit,ViewChild,ChangeDetectorRef} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule,Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MerchantService} from '../merchant.service';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {ImageUploadComponent} from '../../shared/image-upload/image-upload.component';
import {PdfUploadComponent} from '../../shared/pdf-upload/pdf-upload.component'
import {MatDividerModule} from '@angular/material/divider';
import {map} from 'rxjs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {GoogleMapsModule} from '@angular/google-maps';
import {BasicOpenHoursComponent} from '../../shared/basic-open-hours/basic-open-hours.component';
import {AdvanceOpenHoursComponent} from '../../shared/advance-open-hours/advance-open-hours.component';
import {DataFilterPipe } from '../../shared/pipes/data-filter.pipe';
import {NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {debounceTime, filter} from 'rxjs/operators';
import {fromEvent } from 'rxjs';
import { CustomeMessageComponent } from '../merchant-edit/custome-message/custome-message.component';
import {LoaderComponent} from '../../shared/loader/loader.component';
import Swal from 'sweetalert2';
declare const google: any;
import { preDefineSlots ,
        orderingTypes , 
        companyTypesList,
        thriveMerchant,
        crmOnlyMerchant,
        stepNumber,
        requiredFields,
        validationsForRequiredFields,
        atLeastOneChecked,
        errorMessagesToShow,
        notRequiredFields } from './merchant-fields';
import { NiuPopupComponent } from '../merchant-edit/niu-popup/niu-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';

 // Define a recursive function to loop through form controls and groups
 function recursivelyFindErrors(formGroup: FormGroup | null, errors: any = {}): any {
    if (!formGroup) {
        return errors;
    }
    Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.get(key);
        if (control instanceof FormGroup) {
            if(control.errors && Object.keys(control.errors)?.length){
                errors[key] = control.errors;
            }else{
                // If it's a nested FormGroup, recursively call the function
                const nestedErrors = recursivelyFindErrors(control);
                if (Object.keys(nestedErrors).length > 0) {
                    errors[key] = nestedErrors;
                }
            }
        } else {
            // If it's a FormControl, check for errors
            if (control && control.invalid) {
                errors[key] = control.errors;
            }
        }
    });
    return errors;
}
  
@Component({
    selector: 'app-merchant-edit',
    templateUrl: './merchant-edit.component.html',
    styleUrls: ['./merchant-edit.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        MatButtonModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ImageUploadComponent,
        MatDividerModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        GoogleMapsModule,
        BasicOpenHoursComponent,
        AdvanceOpenHoursComponent,
        DataFilterPipe,
        NgxMatSelectSearchModule,
        CustomeMessageComponent,
        LoaderComponent,
        AccessControlDirective,
        PdfUploadComponent
    ]
})
export class MerchantEditComponent implements OnInit {
    
    accountConfirming(c: AbstractControl): {invalid: boolean} {
        if (c.get('account_number').value !== c.get('confirm_account_number').value) {
            c.get('confirm_account_number').setErrors({notMatch:true}) 
            return {invalid:true} 
        } 
        else {
            c.get('confirm_account_number').setErrors(null);
        }
    }

    @ViewChild(BasicOpenHoursComponent, { static: false }) private basicSettings: BasicOpenHoursComponent;
    @ViewChild(AdvanceOpenHoursComponent, { static: false }) private advanceSettings: AdvanceOpenHoursComponent;
    @ViewChild(CustomeMessageComponent, { static: false }) private customeMessageCom: CustomeMessageComponent;
    @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;
    @ViewChild('search_address', { static: false }) search_address: ElementRef;
    @ViewChild("messagecontents",{ static: false }) public messagecontents: any;
    step = 0;
    cities: any;
    restaurantTypes: any;
    cuisines: any = [];
    plans: any;
    editId: string;
    isEdit: boolean = false;
    merchantForm: FormGroup;
    merchantDetails: any;
    orderingTypes: any = orderingTypes;
    companyTypesList: any = companyTypesList;
    showAdvanceSettings: boolean = false;
    customOpenHours: any = {invalid: false, data: []};
    searchKeyword = new FormControl('');
    map: any;
    searchResults: any = [];
    searchSubscription$: any = null;
    infoWindow: any;
    marker: any;
    preDefineSlots:any = preDefineSlots
    allChains: any;
    posList:any [] = [];
    platforms:any [] = [];
    platformNaming:any={
        "Consumer":"Consumer app only",
        "Direct":"Online store only",
        "Both":"Consumer app & Online store",
        "CRM only":"CRM only",
        "Direct and ONDC":"ONDC & Online store",
        "All Platforms":"All (ONDC, Consumer app & Online store)",
        "ONDC only":"ONDC only",
        "Consumer and ONDC":"ONDC & Consumer app"
    }
    gstInfo:any;
    panInfo:any;
    errorMessage:string;
    public search: FormControl = new FormControl();
    public searchCity:FormControl = new FormControl();
    public searchCuisine:FormControl = new FormControl();
    join_reason:any;
    showStep:any = thriveMerchant;
    // for bank details
    businessTypeList: any = [];
    invalidBankDetailsMessage:string;
    bankDetailsSkip:boolean = false;
    accountDetails = new FormGroup({    
        name: new FormControl('', Validators.required),
        slug: new FormControl(''),//edit Thrive url
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        owner_contact: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        owner_email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
        city: new FormControl('', Validators.required),
        platform: new FormControl('', Validators.required),
        staff_email: new FormControl(''),
        password: new FormControl(''),
        staff_pos: new FormControl('', Validators.required),
        pos_type:new FormControl(''),
        pos_consumer_key: new FormControl(''),
        rista_api_key:new FormControl(''),
        rista_api_token:new FormControl(''),
        delivery_pos_tab_id: new FormControl(''),
        pickup_pos_tab_id: new FormControl(''),
        dine_in_pos_tab_id: new FormControl(''),
        // it will visible only in edit merchant
        hl_integration_key: new FormControl(''),//HL integration
        zoho_customer_id: new FormControl(''),//edit
        zoho_subscription_customer_id: new FormControl(''), //edit
        chain_id:new FormControl(''),//assign chain;
        admin_id:new FormControl(), //account manager id
        joining_reason:new FormControl(''),
        authentication_token: new FormControl('')
    });
    restaurantInfo = new FormGroup({
        restaurant_type: new FormControl('', Validators.required),
        cuisines: new FormControl([], Validators.required),
        logo: new FormControl(''),
        background_image: new FormControl(''),
        average_spend: new FormControl('',[Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        fssai_licensed: new FormControl(true),
        fssai_license_no: new FormControl(''),
        fssai_application_no: new FormControl(''),
        is_fssai_verified: new FormControl(false),
        fssai_application_date: new FormControl(''),

        fssai_certificate: new FormControl(''),
        fssai_expiry_date: new FormControl(''),

        gst_registered: new FormControl(true),
        gst_number: new FormControl(''),
        pan_number: new FormControl(''),
        legal_entity_name: new FormControl(''),
        gst_company_type: new FormControl(''),
        gst_registered_date: new FormControl(''),

        gst_certificate: new FormControl(''),
        // gst_registered_email: new FormControl('', [Validators.required,Validators.email]),
        part_of_hotel: new FormControl(true),
        declaration: new FormControl(true)
    });
    contactDetails =  new FormGroup({
        search_address: new FormControl('',Validators.required),
        outlet_address: new FormControl('', [Validators.required]),
        pincode: new FormControl('', Validators.required),
        contact_number: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        email:  new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
        lat: new FormControl(''),
        lon: new FormControl(''),
        city: new FormControl(''),
        country: new FormControl(''),
        state: new FormControl(''),
        location: new FormControl('',[Validators.required]),
        open_hours: new FormControl('')
    });
    miscellaneous = new FormGroup({
        solutions_entity_id: new FormControl('',[Validators.maxLength(19), Validators.minLength(19), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        // promotional_sender_id: new FormControl('',Validators.pattern(/^[a-zA-Z0-9]+$/)),
        sender_id: new FormControl('',Validators.pattern(/^[a-zA-Z0-9]+$/)),
        token: new FormControl(''),
        waba_id: new FormControl('',[Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        mobile_waba: new FormControl('',[ Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        is_dynamic_otp_for_redemption: new FormControl(false), //dynamic otp enable disable
        // custom message
        is_custom_messages: new FormControl(false)
    });
    bank_account_details = new FormGroup({
            account_holder_name: new FormControl('',[Validators.required]),
            account_number: new FormControl('',[Validators.required]),
            confirm_account_number: new FormControl('',[Validators.required]),
            ifsc_code: new FormControl('',[Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)]),
            business_type: new FormControl('',[Validators.required]),
            bank_name: new FormControl('',[Validators.required]),
            branch: new FormControl('',[Validators.required]),
            city: new FormControl('',[Validators.required]),
            state: new FormControl('',[Validators.required]),
            district: new FormControl(''),
            is_verified: new FormControl(false,Validators.requiredTrue)
        },
        {validators: this.accountConfirming}
    );
    pricing = new FormGroup({
        // direct
        microsite_fee: new FormControl('3', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
        payment_gateway_plan_id: new FormControl(1, Validators.required),
    
        availability: new FormGroup(
            {
              delivery: new FormControl(true),
              take_away: new FormControl(false),
              dine_in: new FormControl(false),
            },
            {
              validators: atLeastOneChecked(),
            }
          ),
        // consumer only  
        thrive_exclusive: new FormControl(false),
        consumer_app_new_user_fee: new FormControl('14', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
        consumer_app_repeat_user_fee: new FormControl('10', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
        //ondc only
        ondc_max_fee: new FormControl('5',[Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)])
    });

    inProccess:boolean = false;
    notInitialised:boolean=true;
    showLead:boolean=false;
    showChain:boolean=true;
    showMerchant:boolean=true;
    disablePartOfChain:boolean=false;
    showLoader:boolean=true;
    minDate = new Date();
    chainIdExists:boolean=true;
    accountManagerList:any;
    showPlatform:any={};
    constructor(private merchantService: MerchantService, 
                private fb: FormBuilder, 
                public dialog: MatDialog,
                private router: Router, 
                private ref: ChangeDetectorRef,
                private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.editId = this.route.snapshot.params.id;
        this.isEdit = this.editId === 'new'?false:true;
        //fetch business type list
        this.merchantService.getBusinessType().subscribe((data: any) => {
            this.businessTypeList = [];
            for (let k in data.business_types) {
                this.businessTypeList.push({id: k, name: data.business_types[k]});
            }
        });
        this.initialiseFields();
        this.getJoiningReason();
        this.merchantForm.get('restaurant_info.fssai_license_no').valueChanges.subscribe((value: string) => {
            if(this.merchantForm.get('restaurant_info.is_fssai_verified').value && this.merchantForm.get('restaurant_info.fssai_license_no').invalid){
                this.merchantForm.get('restaurant_info.is_fssai_verified').setValue(false);
            }   
        });
    }
                
    initialiseFields(){
        //populate cities list
        this.merchantService.getCities().subscribe((cities) => (this.cities = cities));
        //populate restaurant types
        this.merchantService.getRestaurantTypes().subscribe((fields: any) => (this.restaurantTypes = fields.restaurant_types));
        //populate cuisines list
        this.merchantService.getCuisines().subscribe((cuisines: any) => (this.cuisines = cuisines));
        //populate plans list
        this.merchantService.getPlans().subscribe((plans: any) => (this.plans = plans));
        this.createForm();
        if (this.editId !== 'new'){// in case of merchant edit 
            this.patchForm();
        }else{
            this.showLoader=false;
        }
        //populate pos list
        this.merchantService.getPosList().subscribe((posList:any)=>(this.posList = posList));
        //populate chains list
        this.merchantService.getChains().subscribe((chains:any)=>(this.allChains = chains));
        this.getAccountManagers();
    }
    /**
     * Initialise merchant form 
     */
    createForm() {
        this.merchantForm = this.fb.group({
            role: new FormControl('',Validators.required), 
            part_of_chain: new FormControl(''),
            account_details:this.accountDetails,
            restaurant_info:this.restaurantInfo,
            contact_details:this.contactDetails,
            miscellaneous: this.miscellaneous,
            bank_account_details:this.bank_account_details,
            pricing: this.pricing,
            contract: new  FormControl(),
            contract_copy: new  FormControl()
        });
    }

    /**
     * initialise values of the form
     */
    patchForm() {
        this.merchantService.getMerchantById(+this.editId).subscribe((merchant: any) => {
            this.showLoader=false;
            this.merchantDetails = merchant;
            this.merchantForm.get('role').setValue(this.merchantDetails.account_details.role);
            this.showLead = this.merchantDetails.account_details.role === 'Lead'?true:false;
            switch(this.merchantDetails.account_details.role){
                case 'Merchant':
                    if (Object.keys(this.merchantDetails.bank_account_details).length !== 0) 
                    this.merchantDetails.bank_account_details['confirm_account_number']=this.merchantDetails.bank_account_details.account_number;
                    
                    this.showChain=false;
                    //part of chain
                    let belongsToChain:string;
                    if(merchant.account_details.chain_id){
                        belongsToChain='yes';
                        this.disablePartOfChain=true;
                    }else{
                        this.chainIdExists=false;
                        belongsToChain='no';
                    }
                    this.merchantForm.get('part_of_chain').setValue(belongsToChain);
                    this.isPartOfChain(belongsToChain,merchant.account_details.chain_id);
                    for(let i:number=0;i<requiredFields[merchant.account_details.role]?.length;i++){
                        this.setValidation(requiredFields[merchant.account_details.role][i],validationsForRequiredFields[requiredFields[merchant.account_details.role][i]]);
                    }
                    //open hours
                    this.showAdvanceSettings=merchant.contact_details.open_hours.show_advance_setting;
                    this.preDefineSlots=merchant.contact_details.open_hours.open_hours;
                    for(let i:number=0;i<this.preDefineSlots.length;i++){
                        for(let j:number=0;j<this.preDefineSlots[i].open_slot.length;j++){
                            if(this.preDefineSlots[i].open_slot[j]['end']==='00:00'){
                                this.preDefineSlots[i].open_slot[j]['end']='24:00'
                            }
                        }
                    }
                    break;
                case 'ChainOwner':
                    this.showMerchant=false;
                    this.onMerchantTypeChange(this.merchantDetails.account_details.role);
                    break;
                case 'Lead':
                    this.showChain=false;
                    break;
                case 'Not in use':
                    this.removeFormGroupsValidation();
                    break;
            }
            let staffOrPos:string=merchant.account_details.pos_type&&merchant.account_details.pos_type!=='thrive_staff'?'pos':'staff';
                this.merchantForm.get('account_details.staff_pos').setValue(staffOrPos);
            if(this.merchantDetails.account_details.role === 'Merchant' || this.merchantDetails.account_details.role === 'Lead'){
                //platforms to specify merchant type
                if(merchant.account_details.city){
                    this.onChangeCity({value:merchant.account_details.city});
                }
                // GST 
                this.setOrRemoveValidationOfGST(merchant.restaurant_info.gst_registered);
                // FSSAI
                this.setOrRemoveValidationOfFssai(merchant.restaurant_info.fssai_licensed);
            }

            if(merchant.account_details.role === 'ChainOwner')
            (this.merchantForm.get('account_details') as FormGroup).addControl('outlets', new FormControl(''));

            //prefill values
            (this.merchantForm.get('account_details') as FormGroup).addControl('onboarding_source', new FormControl(''));
            this.merchantForm.patchValue(merchant);
            this.merchantForm.get('account_details.staff_email').setValue(merchant.account_details.staff_email ? merchant.account_details.staff_email: merchant.account_details.id);
            this.merchantForm.get('contract_copy').setValue(merchant.contract);
            //update validation for selected platform
            if(typeof merchant.account_details.platform === 'number'){
                this.onPlatformChange({value:merchant.account_details.platform});
            }
            //verify gst or pan 
            if(merchant.restaurant_info.gst_registered&&merchant.restaurant_info.gst_number){
                this.verifyGSTNumber();
            }else if (!merchant.restaurant_info.gst_registered&&merchant.restaurant_info.pan_number){
                this.verifyPANNumber();
            }
            //get platforms
            //this.getCustomMessageTypes(this.merchantDetails);
        });
    }

    getJoiningReason(){
        this.merchantService.getJoiningReason().subscribe((response:any)=>{
            this.join_reason = response.joining_reason;
        })
    }

    onMerchantTypeChange(merchantType:string){
         switch(merchantType){
            case 'Merchant':
                for(let i:number=0;i<requiredFields[merchantType]?.length;i++){
                    this.setValidation(requiredFields[merchantType][i],validationsForRequiredFields[requiredFields[merchantType][i]]);
                }
                if(!this.isEdit){
                    // GST 
                    this.setOrRemoveValidationOfGST(true);
                    // FSSAI
                    this.setOrRemoveValidationOfFssai(true);
                }
                break;
            case 'ChainOwner':
                this.platforms = [];
                this.merchantForm.get('account_details.city').setValue('Mumbai');
                this.onChangeCity({value:'Mumbai'});
                this.merchantForm.get('part_of_chain').setValue('');
                for(let i:number=0;i<notRequiredFields[merchantType]?.length;i++){
                    this.removeValidation(notRequiredFields[merchantType][i]);
                }
                break;
            case 'Not in use':
                this.removeFormGroupsValidation();
            break;
        }
     }

     removeFormGroupsValidation(){
        for (const key in this.merchantForm.controls) {
            if (this.merchantForm.controls.hasOwnProperty(key)) {
              const control = this.merchantForm.get(key); // Access the control
              if (control instanceof FormGroup) {
                // If it's a nested FormGroup, you can access its controls as well
                for (const nestedKey in control.controls) {
                  if (control.controls.hasOwnProperty(nestedKey)) {
                    const nestedControl = control.get(nestedKey); // Access the nested control
                    console.log(key+'.'+nestedKey);
                    this.removeValidation(key+'.'+nestedKey);
                  }
                }
              }else{
                this.removeValidation(key);
              }
            }
        }  
     }
     /**
      * On choosing whether a merchant belongs to chain or not 
      * @param event value of the radio button selected - yes/no
      */
     isPartOfChain(partOfChain:string,chainId?:number){
        if(partOfChain  === 'yes'){
            this.merchantForm.addControl('chain_id', new FormControl('', Validators.required));
            if(chainId){
                this.merchantForm.get('chain_id').setValue(chainId);
            }
        } else {
            this.merchantForm.removeControl('chain_id');
        }
        this.merchantForm.updateValueAndValidity();
    }
    /**
     * Set validations basis whether merchant is fssai registered or not
     * @param isFssaiPresent yes/no
     */
    setOrRemoveValidationOfFssai(isFssaiPresent:boolean) {
        if (isFssaiPresent) {
            //remove validation
            this.removeValidation('restaurant_info.fssai_application_no');
            this.removeValidation('restaurant_info.fssai_application_date');
            // set validation 
            this.setValidation('restaurant_info.fssai_license_no', [
                Validators.required,
                Validators.pattern(/^\d+$/),
                Validators.maxLength(14),
                Validators.minLength(14),
            ]);
            this.setValidation('restaurant_info.is_fssai_verified',[Validators.requiredTrue]);
        } else {
            // remove validation
            this.removeValidation('restaurant_info.fssai_license_no');
            this.removeValidation('restaurant_info.is_fssai_verified');
            // set validation
            this.setValidation('restaurant_info.fssai_application_no',  [
                Validators.required,
                Validators.pattern(/^\d+$/),
                Validators.maxLength(17),
                Validators.minLength(17),
            ]);
            this.setValidation('restaurant_info.fssai_application_date',  [Validators.required]);
        }
    }

    /**
     * Set validations basis whether merchant is gst registered or not
     * @param isGstRegistered yes/no
     */
    setOrRemoveValidationOfGST(isGstRegistered:boolean) {
        if (isGstRegistered) {
            // set validation 
            this.setValidation('restaurant_info.gst_number',[Validators.required,Validators.pattern('^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$')]);
            this.setValidation('restaurant_info.gst_registered_date',[Validators.required]);
            this.setValidation('restaurant_info.part_of_hotel',[Validators.required]);
            // remove validation
            this.removeValidation('restaurant_info.pan_number');
            this.removeValidation('restaurant_info.declaration');
        } else {
            // set validation
            this.setValidation('restaurant_info.pan_number',[Validators.required,Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]{1}$")]);
            this.setValidation('restaurant_info.declaration',[Validators.requiredTrue]);
            // remove validation
            this.removeValidation('restaurant_info.gst_number');
            this.removeValidation('restaurant_info.gst_registered_date');
            this.removeValidation('restaurant_info.part_of_hotel');
        }
        this.merchantForm.updateValueAndValidity();
    }

    setValidation(field:any,validations:any){
        this.merchantForm.get(field).setValidators(validations);
        this.merchantForm.get(field).updateValueAndValidity();
    }

    removeValidation(field:any){
        this.merchantForm.get(field).clearValidators();
        this.merchantForm.get(field).updateValueAndValidity();
    }

    /**
     * verify fssai license number
     */
    verifyFSSAI(){
        this.merchantService.verifyFSSAINumber(this.merchantForm.get('restaurant_info.fssai_license_no').value).subscribe((response:any)=>{
            this.merchantForm.get('restaurant_info.is_fssai_verified').setValue(response.is_valid);
            if(!response.is_valid)
            this.merchantForm.get('restaurant_info.fssai_license_no').setErrors({invalid_fssai:true})
        },(error:any)=>{
            this.merchantForm.get('restaurant_info.is_fssai_verified').setValue(error.error.is_valid);
            if(!error.error.is_valid){
                this.merchantForm.get('restaurant_info.fssai_license_no').setErrors({invalid_fssai:true});
                this.errorMessage = error.error.message;
                this.merchantForm.get('restaurant_info.fssai_license_no').markAsTouched();
            }
        })
    }

    /**
     * Updated validations when the platform of the merchant - Consumer, Microsite, Both or CRM is updated
     * @param platformSelected 
     */
    onPlatformChange(platformSelected:any){
    
        switch (platformSelected.value) {
            case 0://direct
                this.removeValidation('pricing.consumer_app_new_user_fee');
                this.removeValidation('pricing.consumer_app_repeat_user_fee');
                this.setValidation('pricing.microsite_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.payment_gateway_plan_id',[Validators.required]); 
                this.removeValidation('pricing.ondc_max_fee'); 
                this.setValidation('pricing.availability',[atLeastOneChecked()]) 
            break;
            case 1://Consumer
                this.setValidation('pricing.consumer_app_new_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.consumer_app_repeat_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.removeValidation('pricing.microsite_fee');
                this.removeValidation('pricing.payment_gateway_plan_id');
                this.removeValidation('pricing.ondc_max_fee');
                this.setValidation('pricing.availability',[atLeastOneChecked()])   
            break;
            case 2: //both
                this.setValidation('pricing.consumer_app_new_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.consumer_app_repeat_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.microsite_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.payment_gateway_plan_id',[Validators.required]);
                this.removeValidation('pricing.ondc_max_fee'); 
                this.setValidation('pricing.availability',[atLeastOneChecked()]) 
            break;
            case 4://ondc
                this.setValidation('pricing.ondc_max_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.removeValidation('pricing.microsite_fee');
                this.removeValidation('pricing.payment_gateway_plan_id'); 
                this.removeValidation('pricing.consumer_app_new_user_fee');
                this.removeValidation('pricing.consumer_app_repeat_user_fee'); 
                this.removeValidation('pricing.availability');
            break;
            case 5://direct and ondc
                this.setValidation('pricing.microsite_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.payment_gateway_plan_id',[Validators.required]); 
                this.setValidation('pricing.ondc_max_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.removeValidation('pricing.consumer_app_new_user_fee');
                this.removeValidation('pricing.consumer_app_repeat_user_fee'); 
                this.setValidation('pricing.availability',[atLeastOneChecked()])
            break;
            case 6://consumer and ondc
                this.setValidation('pricing.consumer_app_new_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.consumer_app_repeat_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.ondc_max_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.removeValidation('pricing.microsite_fee');
                this.removeValidation('pricing.payment_gateway_plan_id');
                this.setValidation('pricing.availability',[atLeastOneChecked()]) 
            break;
            case 7://all platforms
                this.setValidation('pricing.consumer_app_new_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.consumer_app_repeat_user_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.ondc_max_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.microsite_fee',[Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
                this.setValidation('pricing.payment_gateway_plan_id',[Validators.required]); 
                this.setValidation('pricing.availability',[atLeastOneChecked()])
            break;
        } 

        if(platformSelected.value !== 3){//consumer/direct/both
            this.showStep=thriveMerchant;
            //fssai
            if(this.merchantForm.get('role').value==='Merchant'
                &&this.merchantForm.get('restaurant_info.fssai_licensed').value!==true
                &&this.merchantForm.get('restaurant_info.fssai_licensed').value!==false){
                this.merchantForm.get('restaurant_info.fssai_licensed').setValue(true);
                this.setOrRemoveValidationOfFssai(true);
            }
        } else {//CRM Only
            this.removeValidation('pricing.microsite_fee');
            this.removeValidation('pricing.payment_gateway_plan_id');
            this.removeValidation('pricing.consumer_app_new_user_fee');
            this.removeValidation('pricing.consumer_app_repeat_user_fee');
            this.removeValidation('pricing.availability');
            this.showStep=crmOnlyMerchant;
            //fssai
            ['restaurant_info.fssai_licensed',
             'restaurant_info.fssai_license_no',
             'restaurant_info.fssai_application_no',
             'restaurant_info.fssai_application_date'].forEach((field:string)=>{
                 this.removeValidation(field);
            });
            //staff/pos
            if(this.merchantForm.get('account_details.staff_pos').value==='staff'){
                this.merchantForm.get('account_details.staff_pos').setValue('null');
            }
        }
    }

    getAccountManagers(){
        this.merchantService.getAccountManagerList().subscribe((response)=>{
            this.accountManagerList = response;
        });
    }
    
    /**
     * Verify entered gst number is valid or not
     */
    verifyGSTNumber(){
        this.merchantService.verifyGstNumber(this.merchantForm.get('restaurant_info.gst_number').value,this.editId).subscribe((gstInfo)=>{
            this.gstInfo = gstInfo;
            this.gstInfo['message'] = 'GST Number Verified';
            this.merchantForm.get('restaurant_info').patchValue({
                legal_entity_name: this.gstInfo.data.business_name,
                gst_company_type: this.gstInfo.data.constitution_of_business,
                gst_registered_date: this.gstInfo.data.date_of_registration,
            });
        },(err) => {
            this.gstInfo = {
                'message' :  err.error.message,
                'is_valid' : err.error.is_valid
            }
        });
    }

    clearGstInput(){
        if(this.merchantForm.get('restaurant_info.gst_number').invalid){
            this.gstInfo = null;
            this.merchantForm.get('restaurant_info').patchValue({
                legal_entity_name: '',
                gst_company_type: '',
                gst_registered_date: ''
            });
        }
    }

    clearPanInput(){
        if(this.merchantForm.get('restaurant_info.pan_number').invalid){
            this.panInfo = null;
            this.merchantForm.get('restaurant_info').patchValue({
                legal_entity_name: '',
                gst_company_type: '',
            });
        }
    }

    verifyPANNumber(){
        this.merchantService.verifyPanNumber(this.merchantForm.get('restaurant_info.pan_number').value).subscribe((panInfo:any)=>{
            this.panInfo = panInfo
            this.panInfo['message'] = 'PAN Number Verified';
            this.merchantForm.get('restaurant_info').patchValue({
                legal_entity_name: this.panInfo.data.full_name
            });
        },(err) => {
            this.panInfo = {
                'message' :  err.error.message,
                'is_valid' : err.error.is_valid
            }
        });
    }

    /**
     * Remove form group of bank details 
     */
    skipBanckDetails(e:any){
        e.stopPropagation();
        this.bankDetailsSkip = true;
        this.merchantForm.removeControl('bank_account_details');
        this.merchantForm.addControl('bank_account_details',new FormGroup({}));
    }

    /**
     * Add form group of bank details 
     */
    addbankDetails(e:any){
        e.stopPropagation();
        this.bankDetailsSkip = false;
        this.merchantForm.removeControl('bank_account_details');
        this.merchantForm.addControl('bank_account_details',this.bank_account_details);
    }
    
    //location and contact details
    /**
     * Initialise google places autocomplete search
     */
    initialiseAddressSearch() {
        this.searchSubscription$ = fromEvent(
          this.search_address.nativeElement,
          'keyup'
        )
          .pipe(
            // get value
            map((event: any) => {
              if (!event.target.value.trim()) {
                this.searchResults = [];
                this.merchantForm.get('contact_details.search_address').setValue('');
              }
              return event.target.value;
            }),
            // if character length greater then 2
            filter((res) => res.length > 2),
            // Time in milliseconds between key events
            debounceTime(500)
            // If previous query is diffent from current
            // , distinctUntilChanged()
            // subscription for response
          )
          .subscribe((search_key: string) => {
            this.callAutocomplete(search_key);
          });
    }

    /**
     * track by function for addresses
     * @param index address index
     * @param address address searched for
     * @returns id of address
     */
    trackByAddressId(index: number, address: any) {
        return address.id;
    }

    /**
     * call autocomplete function for addreeses list
     * @param q searched string
     */
    callAutocomplete(q: string) {
        this.merchantService.getMapLocation('/v2/maps?input=' + q).subscribe((data: any) => {
            this.searchResults = data.predictions;
            this.ref.detectChanges();
        });
    }

    /**
     * choose the selected address
     * @param address chosen address object
     */
    chooseSearchedAdd(address: any) {
        ['location', 'city', 'country', 'pincode','state'].forEach((elem) => {
            this.merchantForm.get('contact_details.'+[elem]).reset();
        });
        this.merchantService.getMapLocation('/v2/maps/' + address.place_id).subscribe((result: any) => {
            if (!result) {
            this.searchResults = [];
            window.alert('Please search and select the full address');
            // this.ref.detectChanges();
            return;
            }
            this.merchantForm.get('contact_details.city').setValue(result.city);
            this.merchantForm.get('contact_details.country').setValue(result.country);
            this.merchantForm.get('contact_details.pincode').setValue(result.pincode);
            this.merchantForm.get('contact_details.lat').setValue(result.lat);
            this.merchantForm.get('contact_details.lon').setValue(result.lon);
            this.merchantForm.get('contact_details.state').setValue(result.state);
            this.merchantForm.get('contact_details.location').setValue(result.locality);
            this.merchantForm.get('contact_details.search_address').setValue(result.address);
            this.searchKeyword.setValue(result.address);
            let pos = {
            coords: {
                latitude: result.lat,
                longitude: result.lon,
            },
            };
            this.setPosition(pos);
            this.searchResults = [];
            this.ref.detectChanges();
        });
    }
    
    /**
     * Move the market to correct position on the map
     * @param initPos latitude & longitude of selected location
     */
    initialiseMap(initPos: any) {
        this.map = new google.maps.Map(this.mapContainer.nativeElement, {
            center: initPos,
            zoom: 15,
            disableDefaultUI: true,
            gestureHandling: 'greedy',
            streetViewControl: false,
        });
        this.infoWindow = new google.maps.InfoWindow();
            let marker = new google.maps.Marker({
            position: initPos,
            map: this.map,
            animation: google.maps.Animation.DROP,
        });
        this.marker = marker;
        const moveMarker = this.moveMarker;
        google.maps.event.addListener(this.map, 'drag', function () {
            marker.setPosition(this.getCenter()); // set marker position to map center
        });
        google.maps.event.addListener(this.map, 'dragend', function () {
            marker.setPosition(this.getCenter()); // set marker position to map center
            let position = {
                coords: {
                latitude: this.getCenter().lat(),
                longitude: this.getCenter().lng(),
                },
            };
            moveMarker(position); // update position display
        });
    }

    /**
     * 
     * @param position location coordinates
     */
    setPosition = (position: any) => {
        let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        this.map.setCenter(pos);
        this.marker.setPosition(pos);
        this.infoWindow.setContent('<b>Location found!</b>');
        this.infoWindow.open(this.map, this.marker);
    };

    /**
     * move marker on map
     * @param position location coordinates
     */
    moveMarker = (position: any) => {
        ['location', 'city', 'country', 'pincode','state'].forEach((elem) => {
            this.merchantForm.get('contact_details.' + elem).reset();
        });
        this.merchantService.getMapLocation('/v2/maps/reverse_geocode?lat=' + position.coords.latitude + '&lon=' + position.coords.longitude).subscribe((result: any) => {
            if (!result) {
                this.searchResults = [];
                window.alert('Please search and select the full address');
                this.ref.detectChanges();
                return;
            }
            this.merchantForm.get('contact_details.city').setValue(result.city);
            this.merchantForm.get('contact_details.country').setValue(result.country);
            this.merchantForm.get('contact_details.pincode').setValue(result.pincode);
            this.merchantForm.get('contact_details.lat').setValue(result.lat); 
            this.merchantForm.get('contact_details.lon').setValue(result.lon);
            this.merchantForm.get('contact_details.state').setValue(result.state);
            this.merchantForm.get('contact_details.location').setValue(result.locality);
            this.merchantForm.get('contact_details.search_address').setValue(result.address);
            this.searchKeyword.setValue(result.address);
            this.searchResults = [];
            this.setPosition(position);
            this.ref.detectChanges();
        });
    };

    getFullAddress(){
        let position = {
            coords: {
            latitude:  this.merchantForm.get('contact_details.lat').value,
            longitude: this.merchantForm.get('contact_details.lon').value,
            },
        };
        const moveMarker = this.moveMarker;
        moveMarker(position); 
    }

    closeSearchResults() {
        this.searchResults = [];
        this.ref.detectChanges();
    }

    getOpenHours(event: any) {
        this.customOpenHours = event;
    }

    toggleAdvanceSettings() {
        this.showAdvanceSettings = !this.showAdvanceSettings;
    }
    
    /**
     * triggers when a a step in the form is opened 
     */
    setStep(index:number) {
        this.step = index;
        //on step location and contact details initialise the map
        if(this.notInitialised && this.step === 3 && this.merchantForm.get('role').value === 'Merchant'){
            // map
            if (google) {
                this.initialiseAddressSearch();
                let initPos: any = {
                    lat: Number(this.merchantForm.get('contact_details.lat').value || 19.076),
                    lng: Number(this.merchantForm.get('contact_details.lon').value || 72.8777)
                };
                this.search_address.nativeElement.value = this.merchantForm.get('contact_details.search_address').value;
                this.initialiseMap(initPos);
            }
        }
    }

    checkForUniqueSlug(){
        this.merchantService.getUniqueSlug({
            slug:this.merchantForm.get('account_details.slug').value,
            auth_token:this.merchantDetails.account_details.authentication_token
        }).subscribe((result)=>{
        },(err)=>{
            let errMsg:string='Something went wrong. Please try agaian later.';
            if(err?.error?.error){
                errMsg=err.error.error;
            }
            Swal.fire('Error', errMsg, 'error');
        });
    }

    /**
     * get available platforms list city wise
     */
    onChangeCity(event:any){
        this.merchantService.getEligiblePlatforms(event.value).subscribe((platfoms:any)=>{
            this.platforms = platfoms;
        })
    }

    /**
     * on choosing staff or pos 
     * @param event 
     */
    staffOrPos(event:any){
        if(event.value === 'pos'){
            this.merchantForm.get('account_details.pos_type').setValidators(Validators.required);
        } else {
            this.merchantForm.get('account_details.pos_type').clearValidators();
            this.merchantForm.get('account_details.pos_type').setValue('thrive_staff');
        }
        this.merchantForm.get('account_details.pos_type').updateValueAndValidity();
    }

    onChangeChain(event:any){
        if(this.chainIdExists){
            this.clearForm();
        }
        if(this.isEdit&&!this.chainIdExists){
            this.chainIdExists=true;
        }
        this.getChainInfo(event.value);
    }

    getChainInfo(chainId:number){
        this.merchantService.getChainOwnersDetails(chainId).subscribe((chainInfo:any)=>{
            if(this.isEdit){
                this.merchantForm.get('account_details.zoho_customer_id').setValue(chainInfo.account_details.zoho_customer_id);
                this.merchantForm.get('account_details.zoho_subscription_customer_id').setValue(chainInfo.account_details.zoho_subscription_customer_id);
            } else {
                //ACCOUNT DETAILS
                ['name','first_name','last_name','owner_contact','owner_email','city','platform','pos_type','zoho_customer_id','zoho_subscription_customer_id'].forEach((key:string)=>{
                    this.merchantForm.get('account_details.'+key).setValue(chainInfo.account_details[key]);
                });
                let staffOrPos:string=chainInfo.account_details.pos_type&&chainInfo.account_details.pos_type!=='thrive_staff'?'pos':'staff';
                this.merchantForm.get('account_details.staff_pos').setValue(staffOrPos);
                //fetch platforms according to the city the chain belongs to
                if(chainInfo.account_details.city?.length){
                    this.onChangeCity({value:chainInfo.account_details.city});
                }
                //update validation for selected platform
                if(typeof chainInfo.account_details.platform === 'number'){
                    this.onPlatformChange({value:chainInfo.account_details.platform});
                }
                //RESTAURANT INFO
                ['restaurant_type','cuisines','background_image','logo','average_spend'].forEach((key:string)=>{
                    this.merchantForm.get('restaurant_info.'+key).setValue(chainInfo.restaurant_info[key]);
                });
                //LOCATION AND CONTACT DETAILS
                if(chainInfo.contact_details.email){
                    this.merchantForm.get('contact_details.email').setValue(chainInfo.contact_details.email);
                }
                //open hours
                if(chainInfo.contact_details?.open_hours?.open_hours?.length
                    &&chainInfo.contact_details.open_hours.open_hours[0]?.open_slot?.length){
                    this.showAdvanceSettings=chainInfo.contact_details.open_hours.show_advance_setting;
                    this.preDefineSlots=chainInfo.contact_details.open_hours.open_hours;
                }
                //MISCELLANEOUS
                this.merchantForm.get('miscellaneous').patchValue(chainInfo.miscellaneous);
                //PRICING
                this.merchantForm.get('pricing').patchValue(chainInfo.pricing);
            }
        });
    }

    onUploadCertificate(uploadData: any, key: string){
        this.merchantForm.get('restaurant_info.'+key).setValue(uploadData);
    }

    onImageUpload(image: any, key: string) {
        if(key != 'contract'){
            this.merchantForm.get('restaurant_info.'+key).setValue(image);
        } else {
            this.merchantForm.get(key+'_copy').setValue(image);
            const base64 = image.slice(28);
            this.merchantForm.get(key).setValue(base64);
        }
    }
    
    clearForm(){
        switch(this.merchantForm.get('role').value){
            case 'Merchant':
                this.merchantForm.get('account_details').reset();
                this.merchantForm.get('restaurant_info').reset();
                this.merchantForm.get('contact_details').reset();
                this.merchantForm.get('miscellaneous').reset();
                this.merchantForm.get('pricing').reset();
                this.gstInfo=null;
                this.panInfo=null;
                break;
            case 'ChainOwner':
                this.merchantForm.get('contact_details').reset();
                break;
            case 'Lead':
                break;
            case 'Not in use':
                break;
        }
    }
    
    // IFSC code validation
    verifyIFSCCode(value:string) {
        this.merchantForm.get('bank_account_details').patchValue({ifsc_code: value.toUpperCase()});
        this.merchantForm.get('bank_account_details.bank_name').setValue('');
        this.merchantForm.get('bank_account_details.branch').setValue('');
        this.merchantForm.get('bank_account_details.city').setValue('');
        this.merchantForm.get('bank_account_details.state').setValue('');
        this.merchantForm.get('bank_account_details.district').setValue('');
        this.merchantForm.get('bank_account_details.is_verified').setValue(false);
        if ( value.length === 11) {
            this.merchantService.getBankDetails(value).subscribe((data: any) => {
                this.merchantForm.get('bank_account_details').patchValue(data);
            },(error: any) => {
                if (error.error.error_message === 'Ifsc code is not valid! Please enter valid ifsc code.') {
                    this.merchantForm.get('bank_account_details.ifsc_code').setErrors({
                        incorrect: true
                    });
                }
            });
        }
    }

    // allow number only
    numberOnly(event: any): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    // Validate account number with back ifsc code 
    verifyBankDetails() {
        if (this.merchantForm.get('bank_account_details.account_number').value && this.merchantForm.get('bank_account_details.ifsc_code').value) {
            this.merchantService.verifyBankDetails(this.merchantForm.get('bank_account_details.account_number').value, this.merchantForm.get('bank_account_details.ifsc_code').value).subscribe((res:any) => {
                this.merchantForm.get('bank_account_details.is_verified').setValue(res.is_valid)
                this.merchantForm.get('bank_account_details').patchValue({
                    account_holder_name: res.data.full_name,
                    is_verified: true
                });
            },(err) => {
                this.invalidBankDetailsMessage = (err.error && err.error.message) ? err.error.message :'Something went wrong. Please try again later';
                this.merchantForm.get('bank_account_details.ifsc_code').setErrors({notvalid:true});
                this.merchantForm.get('bank_account_details.is_verified').setValue(err.error.is_valid);
            });
        } else {
            this.merchantForm.get('bank_account_details').markAllAsTouched();
        }
    }

    unVerify(){
        this.merchantForm.get('bank_account_details.is_verified').setValue(false);
    }

    // Converts a date to ISO string in UTC
    toUTCString(date: Date): string {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }

    submitForm(){  
        if((this.merchantForm.get('miscellaneous.waba_id').value&&this.merchantForm.get('miscellaneous.waba_id').valid&&!this.merchantForm.get('miscellaneous.token').value)
        ||(!this.merchantForm.get('miscellaneous.waba_id').value&&this.merchantForm.get('miscellaneous.token').value&&this.merchantForm.get('miscellaneous.token').valid)){
            Swal.fire('Error', 'WABA Id & Whatsapp token both are required', 'error');
            return;
        }
        if((this.merchantForm.get('miscellaneous.is_custom_messages').value&&this.merchantForm.get('miscellaneous.is_custom_messages').valid)
        &&(!this.merchantForm.get('miscellaneous.solutions_entity_id').value
            ||!this.merchantForm.get('miscellaneous.solutions_entity_id').valid
            ||!this.merchantForm.get('miscellaneous.sender_id').value)
            ||!this.merchantForm.get('miscellaneous.sender_id').valid){
            Swal.fire('Error', 'To activate custom messages, entity id and sender id should be present', 'error');
            return;
        }
        if(this.merchantForm.valid){
            this.inProccess=true;
            this.merchantForm.value.account_details['role']=this.merchantForm.value.role;
            this.merchantForm.value.account_details['owner_name'] = this.merchantForm.value.account_details.first_name+' '+this.merchantForm.value.account_details.last_name;
            if(this.merchantForm.value.restaurant_info?.fssai_expiry_date)
            this.merchantForm.value.restaurant_info.fssai_expiry_date = this.toUTCString(new Date(this.merchantForm.value.restaurant_info?.fssai_expiry_date));

            //check for POS
            if(this.merchantForm.get('account_details.staff_pos')?.value === 'pos'&& !['posist','ristaapps'].includes(this.merchantForm.get('account_details.pos_type')?.value)){
                this.merchantForm.get('account_details.delivery_pos_tab_id').setValue(null);
                this.merchantForm.get('account_details.pickup_pos_tab_id').setValue(null);
                this.merchantForm.get('account_details.dine_in_pos_tab_id').setValue(null);
            }
            if(this.merchantForm.get('role').value === 'Merchant'){
                if (this.showAdvanceSettings) {
                    this.advanceSettings.submitForm();
                } else {
                    this.basicSettings.submitForm();
                }
                if (!this.customOpenHours.invalid) {
                    let openHoursObj:any={};
                    let openHours:Array<any>=[];
                    if (this.showAdvanceSettings) {
                        for(let i:number=0;i<this.customOpenHours.value?.open_hours?.length;i++){
                            let slots = JSON.parse(JSON.stringify(this.customOpenHours.value.open_hours[i].open_slot));
                            for(let j:number=0;j<slots.length;j++){
                                if(slots[j]["end"]==='24:00'){
                                    slots[j]["end"]='00:00'
                                }
                            }
                            let obj = {
                                day:this.customOpenHours.value.open_hours[i].day,
                                closed:!this.customOpenHours.value.open_hours[i].is_open,
                                open_slot:slots
                            };
                            openHours.push(obj);
                        }
                        openHoursObj['show_advance_setting']=true;
                        openHoursObj['open_hours']=openHours;
                    } else {
                        let days:Array<string>=  ['Monday', 'Tuesday', 'Wednesday','Thursday', 'Friday', 'Saturday', 'Sunday'];
                        for(let i:number=0;i<days.length;i++){
                            let slots = JSON.parse(JSON.stringify(this.customOpenHours.value.open_slot));
                            for(let j:number=0;j<slots.length;j++){
                                if(slots[j]["end"]==='24:00'){
                                    slots[j]["end"]='00:00'
                                }
                            }
                            let obj = {
                                day:days[i],
                                closed:!this.customOpenHours.value.days.includes(days[i]),
                                open_slot:slots
                            };
                            openHours.push(obj);
                        }
                        openHoursObj['show_advance_setting']=false;
                        openHoursObj['open_hours']=openHours;
                    }
                    this.merchantForm.value.contact_details.open_hours = openHoursObj;
                    if(this.editId === 'new'){
                        this.createMerchant();
                    } else {
                        this.editMerchant();
                    }
                }else{
                    this.inProccess=false;
                    Swal.fire('Error', 'There is error in open hours please check and rectify', 'error');
                }
            } else {
                if(this.editId === 'new'){
                    this.createMerchant();
                } else {
                    this.editMerchant();
                }
            }
        } else{
            const allErrors = recursivelyFindErrors(this.merchantForm);
            if(stepNumber[Object.keys(allErrors)[0]]===this.step){
                this.step=0;
            }
            let msg:string=`<div style="max-height: 200px; overflow-y: auto;text-align:left;">Following fields have error. Please scroll to view them all.<br/>${this.generateErrorMessages(allErrors)}</div>`;
            Swal.fire({
                title: 'Error',
                html: msg, // Insert your HTML content here
                icon: 'error'}).then((e)=>{
                this.highlightAndScrollToFirstError(allErrors);
            }); 
        }
    }

    generateErrorMessages(errors:any) {
        let message:string='';
        for (const parentKey in errors) {
            for(const childKey in errors[parentKey]){
                for(const grandChildKey in errors[parentKey][childKey]){
                    message+=`<span style="text-transform: capitalize;">${childKey.replace(/_/g, ' ')}</span> in ${parentKey.replace(/_/g, ' ')} <span style="color:red">${errorMessagesToShow[grandChildKey]}</span><br/>`;
                }
            }
        }
        return message;
    }


      /**
       * highlight all the errors that come while submitting the form and before hitting the API
       * @param errors 
       */
    highlightAndScrollToFirstError(errors: any) {
        // Find the first error control
        const firstErrorGroupName = Object.keys(errors)[0];
        const firstErrorControlName = Object.keys(errors[firstErrorGroupName])[0];
        this.setStep(stepNumber[firstErrorGroupName]);
        this.merchantForm.markAllAsTouched();
        if (firstErrorControlName) {
          const firstErrorControl = this.merchantForm.get(firstErrorGroupName+'.'+firstErrorControlName);
          if (firstErrorControl) {
            // Scroll to the error element
            const errorElement = document.getElementById(firstErrorControlName);
            if (errorElement) {
              errorElement.scrollIntoView({ behavior: 'smooth' });
            }
            // Optionally, you can add CSS styling to the error element here
            // For example: firstErrorControlName.classList.add('error');
          }
        }
    }
      
    /**
     * create a new merchant
     */
    createMerchant(){
        this.merchantService.createMerchant(this.merchantForm.value).subscribe((result:any)=>{
            this.inProccess=false;
            Swal.fire('Success', 'Merchant created!', 'success');
            setTimeout(() => this.router.navigateByUrl('/merchant'), 1000);
        },(err)=>{
            this.inProccess=false;
            if(err?.status === 422){
                Swal.fire('Error', err?.error?.errors[0], 'error');
            } else {
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.error){
                    errMsg=err?.error?.error;
                }
                Swal.fire('Error', errMsg, 'error');
            }
        });
    }

    /**
     * update existing merchant
     */
    editMerchant(){
        switch(this.merchantForm.value.role){
            case 'Not in use': // NIU reason
                const dialog_ref = this.dialog.open(NiuPopupComponent, {
                    width:'60vw',
                    panelClass: ['thrive-dialog', 'sm-full_width_dailog'],
                    data:''
                });
                dialog_ref.afterClosed().subscribe((result: any) => {
                    if(result !== undefined){
                        this.merchantForm.value['niu_reasons']=result;
                        this.merchantService.editMerchant(this.merchantForm.value,this.merchantDetails.account_details.id).subscribe((result)=>{
                            this.inProccess=false;
                            Swal.fire('Success', 'Changes saved!', 'success');
                            setTimeout(() => this.router.navigateByUrl('/merchant'), 1000);
                        },(err)=>{
                            this.inProccess=false;
                            let errMsg:string='Something went wrong. Please try agaian later.';
                            if(err?.error?.error){
                                errMsg=err?.error?.error;
                            }
                            Swal.fire('Error', errMsg, 'error');
                        });
                    }
                });  
            break;
            default:
                if(this.merchantForm.value.miscellaneous.is_custom_messages&&this.customeMessageCom){
                    this.customeMessageCom.submitCustomMessage();
                }
                this.merchantService.editMerchant(this.merchantForm.value,this.merchantDetails.account_details.id).subscribe((result)=>{
                    this.inProccess=false;
                    Swal.fire('Success', 'Changes saved!', 'success');
                    setTimeout(() => this.router.navigateByUrl('/merchant'), 1000);
                },(err)=>{
                    this.inProccess=false;
                    let errorMessage:string;
                    if(err?.status === 422){
                        errorMessage = err?.error?.errors ? err?.error?.errors[0] : 'Something went wrong. Please try agaian later.';
                        Swal.fire('Error', errorMessage, 'error');
                    } else{
                        let errMsg:string='Something went wrong. Please try agaian later.';
                        if(err?.error?.error){
                            errMsg=err?.error?.error;
                        }
                        Swal.fire('Error', errMsg, 'error');
                    }
                });
        }
    }
}