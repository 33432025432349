import { Component,ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SafePipe } from '../pipes/safe.pipe';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-pdf-upload',
  standalone: true,
  imports: [CommonModule,MatIconModule,MatDialogModule,SafePipe],
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss']
})
export class PdfUploadComponent {
  @Input() fileSize:string;
  @Input() pdfUrl: any;
  @Output() uploadPdf = new EventEmitter();
  @ViewChild('itemPdfUpload', {static: false}) itemPdfUpload: ElementRef;
  fileName:string=null;
  
  constructor(public dialog: MatDialog) {}

  removePdf() {
    this.itemPdfUpload.nativeElement.value = '';
    this.pdfUrl = '';
    this.uploadPdf.emit('');
  }

  onPdfUpload(event: any) {
    if (event.target.files[0]?.size <= 5242880) {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e: any) => {
          this.pdfUrl = reader.result;
          this.uploadPdf.emit(reader.result);
          Swal.fire('File added successfully', 'success');  
        };
    } else {
        Swal.fire('Error', 'Maximum size allowed is 5 MB.', 'error');
        this.itemPdfUpload.nativeElement.value = '';
    }
  }

}
