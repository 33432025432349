<div id="offerBulkUpload" class="thrive-container table-grid-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="['/merchant']">
                <mat-icon class="arrow-back">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Upload catalogue rejection</h2>
        </div>
    </div>

    <div class="thrive-card mt-0">
        <div class="thrive-toast_info mb-16">
            <span>Info:</span>
            Upload in .csv format only
        </div>
        <div class="buttons-row">
            <a class="btn-thrive_secondary" mat-button href="https://docs.google.com/spreadsheets/d/1kwTeAtwzCVUkXKkhZfp4XXUsASZ65S3X0KVKU8kkBzw/edit?gid=1675178378#gid=1675178378" target="_blank">VIEW FORMAT</a>
            <button class="btn-thrive_primary ml-20" (click)="uploadInput.click()" mat-button>UPLOAD</button>
            <input type="file" #uploadInput (change)="readExcel($event)" style="display: none" name="blukUpload" accept=".csv" value="upload" />
        </div>
    </div>
</div>
