<div id="merchant-edit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/merchant'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Merchant</h2>
        </div>
    </div>
    <ng-container *ngIf="showLoader">
        <thrive-loader [loaderHeight]="'calc(100vh - 255px)'"></thrive-loader>
    </ng-container>
    <ng-container *ngIf="!showLoader">
        <div class="form-card">
            <form [formGroup]="merchantForm">
                <!-- Type of merchant details -->
                <div class="expansion-body">
                    <div class="flex-form center mb-16">
                        <p class="basetitle">Select type of merchant.<sup>*</sup></p>
                        <div>
                            <mat-radio-group formControlName="role" color="primary" (change)="onMerchantTypeChange($event?.value)">
                                <mat-radio-button value="ChainOwner" *ngIf="showChain">Chain</mat-radio-button>
                                <mat-radio-button value="Merchant" *ngIf="showMerchant">Merchant</mat-radio-button>
                                <!-- only on edit -->
                                <mat-radio-button *ngIf="isEdit&&showLead" value="Lead">Lead</mat-radio-button>
                                <!-- only on edit -->
                                <mat-radio-button *ngIf="isEdit" value="Not in use">Not in use</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="merchantForm.get('role').invalid">Required</mat-error>
                        </div>
                    </div>
                    <!-- only on create and  belongs to chain-->
                    <div *ngIf="merchantForm.get('role')?.value === 'Merchant'">
                        <div class="flex-form center mb-20" [ngClass]="{'disabled-block':disablePartOfChain}">
                            <p class="basetitle">Belongs to a chain?<sup>*</sup></p>
                            <div>
                                <mat-radio-group formControlName="part_of_chain" color="primary" (change)="isPartOfChain($event?.value)">
                                    <mat-radio-button value="yes">Yes</mat-radio-button>
                                    <mat-radio-button value="no">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="merchantForm.get('part_of_chain').invalid">Required</mat-error>
                            </div>
                        </div>
                    </div>
                    <!-- Select and search merchant -->
                    <mat-form-field *ngIf="merchantForm.get('part_of_chain')?.value === 'yes'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20" [ngClass]="{'disabled-block':disablePartOfChain}">
                        <mat-label>Select Chain</mat-label>
                        <mat-select formControlName="chain_id" (selectionChange)="onChangeChain($event)">
                            <mat-option>
                                <ngx-mat-select-search
                                                       placeholderLabel="Search"
                                                       noEntriesFoundLabel="Store not found"
                                                       ngDefaultControl
                                                       [formControl]="search">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option [value]="chain.id" *ngFor="let chain of allChains| datafilter : search.value : ['name','id']">{{chain.name}} ({{chain.id}})</mat-option>
                        </mat-select>
                        <mat-error *ngIf="merchantForm.get('chain_id')?.invalid">Required</mat-error>
                    </mat-form-field>
                </div>
                <mat-accordion *ngIf="merchantForm.get('role')?.value === 'ChainOwner'||(merchantForm.get('role')?.value === 'Merchant' && merchantForm.get('part_of_chain').value)">
                    <!-- account details -->
                    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" *ngIf="showStep[merchantForm.get('role')?.value][1]">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="basetitle">Account details</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="expansion-body" formGroupName="account_details">
                            <div class="flex-form">
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Restaurant name</mat-label>
                                    <input matInput placeholder="Enter restaurant name" formControlName="name" id="name" />
                                    <mat-error *ngIf="merchantForm.get('account_details.name')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <!-- only on edit -->
                                <mat-form-field *ngIf="isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Thrive URL</mat-label>
                                    <input matInput placeholder="Enter Thrive URL" formControlName="slug" id="slug" (blur)="checkForUniqueSlug()" />
                                    <mat-error *ngIf="merchantForm.get('account_details.slug').hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('account_details.slug').hasError('incorrect')">{{merchantForm.get('account_details.slug').getError('incorrect').error}}</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Owner first name</mat-label>
                                    <input matInput  placeholder="Enter owner first name" formControlName="first_name" id="first_name" />
                                    <mat-error *ngIf="merchantForm.get('account_details.first_name')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Owner last name</mat-label>
                                    <input matInput placeholder="Enter owner last name" formControlName="last_name" id="last_name" />
                                    <mat-error *ngIf="merchantForm.get('account_details.last_name')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Owner mobile</mat-label>
                                    <input matInput placeholder="Enter owner mobile" formControlName="owner_contact" id="owner_contact" maxlength="10" minlength="10" />
                                    <mat-error *ngIf="merchantForm.get('account_details.owner_contact').hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('account_details.owner_contact').hasError('maxlength')">Maximum 10 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('account_details.owner_contact').hasError('minlength')">Minimum 10 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('account_details.owner_contact').hasError('pattern')">Digits only</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Owner email </mat-label>
                                    <input matInput placeholder="Enter owner email" formControlName="owner_email" id="owner_email" />
                                    <mat-error *ngIf="merchantForm.get('account_details.owner_email').hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('account_details.owner_email').hasError('pattern')">Incorrect email format</mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="merchantForm.get('role').value !== 'ChainOwner'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>City</mat-label>
                                    <mat-select formControlName="city" id="city" (selectionChange)="onChangeCity($event)">
                                        <mat-option>
                                            <ngx-mat-select-search
                                                                   placeholderLabel="Search"
                                                                   noEntriesFoundLabel="Store not found"
                                                                   ngDefaultControl
                                                                   [formControl]="searchCity">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="option" *ngFor="let option of cities | datafilter : searchCity.value :['']">{{option}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('account_details.city')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="merchantForm.get('account_details.city')?.value" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Select type</mat-label>
                                    <mat-select formControlName="platform" id="platform" (selectionChange)="onPlatformChange($event)">
                                        <mat-option [value]="platform.id" *ngFor="let platform of platforms">{{platformNaming[platform.name]}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('account_details.platform')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <!-- only on edit -->
                                <mat-form-field *ngIf="isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 disabled">
                                    <mat-label>User name </mat-label>
                                    <input readonly matInput placeholder="Enter user name" formControlName="staff_email" id="user_name" />
                                    <mat-error *ngIf="merchantForm.get('account_details.staff_email').hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('account_details.staff_email').hasError('pattern')">Incorrect email format</mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Staff password</mat-label>
                                    <input matInput placeholder="Enter staff password" formControlName="password" id="password" />
                                    <mat-error *ngIf="merchantForm.get('account_details.password')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Staff/POS</mat-label>
                                    <mat-select formControlName="staff_pos" id="staff_pos" (selectionChange)="staffOrPos($event)">
                                        <mat-option value="staff" *ngIf="merchantForm.get('account_details.platform').value!==3">Thrive staff</mat-option>
                                        <mat-option value="pos">POS</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('account_details.staff_pos')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="merchantForm.get('account_details.staff_pos')?.value === 'pos'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Select POS type</mat-label>
                                    <mat-select formControlName="pos_type" id="pos_type">
                                        <mat-option [value]="postype.name" *ngFor="let postype of posList">{{postype.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('account_details.pos_type')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <!-- POS key if POS type is not posist -->
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100"
                                                *ngIf="merchantForm.get('account_details.staff_pos')?.value === 'pos' && merchantForm.get('role').value === 'Merchant'">
                                    <mat-label>POS key</mat-label>
                                    <input matInput placeholder="Enter POS key" formControlName="pos_consumer_key" id="pos_consumer_key" />
                                </mat-form-field>
                                <!-- If pos type rista show rista api key and token -->
                                <mat-form-field *ngIf="isEdit&&merchantForm.get('account_details.pos_type')?.value === 'ristaapps'" 
                                                subscriptSizing="dynamic" appearance="outline" 
                                                floatLabel="never" class="w-100" 
                                                [ngClass]="{'disabled':merchantDetails?.account_details?.rista_configured_chain &&  merchantForm.get('role').value === 'Merchant'}">
                                    <mat-label>Rista API key</mat-label>
                                    <input matInput [readonly]="merchantDetails?.account_details?.rista_configured_chain && merchantForm.get('role').value === 'Merchant'" placeholder="Enter rista api key" formControlName="rista_api_key"  />
                                </mat-form-field>

                                <mat-form-field *ngIf="isEdit&&merchantForm.get('account_details.pos_type')?.value === 'ristaapps'" 
                                                subscriptSizing="dynamic" appearance="outline" 
                                                floatLabel="never" class="w-100" 
                                                [ngClass]="{'disabled':merchantDetails?.account_details?.rista_configured_chain && merchantForm.get('role').value === 'Merchant'}">
                                    <mat-label>Rista API token</mat-label>
                                    <input matInput [readonly]="merchantDetails?.account_details?.rista_configured_chain && merchantForm.get('role').value === 'Merchant'" placeholder="Enter rista api token" formControlName="rista_api_token" />
                                </mat-form-field>
                                <!-- POS keys if POS type is  posist -->
                                <!-- for delivery -->
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100"
                                                *ngIf="merchantForm.get('account_details.staff_pos')?.value === 'pos' && merchantForm.get('role').value === 'Merchant' &&
                                    (merchantForm.get('account_details.pos_type')?.value==='posist' || merchantForm.get('account_details.pos_type')?.value === 'ristaapps')">
                                    <mat-label>Delivery POS key </mat-label>
                                    <input matInput placeholder="Enter POS delivery key" formControlName="delivery_pos_tab_id" id="delivery_pos_tab_id" />
                                </mat-form-field>
                                <!-- for take away -->
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100"
                                                *ngIf="merchantForm.get('account_details.staff_pos')?.value === 'pos' && merchantForm.get('role').value === 'Merchant' &&
                                    (merchantForm.get('account_details.pos_type')?.value==='posist' || merchantForm.get('account_details.pos_type')?.value==='ristaapps')">
                                    <mat-label>Pickup POS key</mat-label>
                                    <input matInput placeholder="Enter POS pickup key" formControlName="pickup_pos_tab_id" id="pickup_pos_tab_id" />
                                </mat-form-field>
                                <!-- for dine-in -->
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100"
                                                *ngIf="merchantForm.get('account_details.staff_pos')?.value === 'pos' && merchantForm.get('role').value === 'Merchant' &&
                                    (merchantForm.get('account_details.pos_type')?.value==='posist' || merchantForm.get('account_details.pos_type')?.value==='ristaapps')">
                                    <mat-label>Dine-in POS key</mat-label>
                                    <input matInput placeholder="Enter POS dine-in key" formControlName="dine_in_pos_tab_id" id="dine_in_pos_tab_id" />
                                </mat-form-field>
                                <!-- only on edit -->
                                <mat-form-field *ngIf="isEdit&&merchantForm.get('role')?.value !== 'ChainOwner'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>HL integrtaion key</mat-label>
                                    <input matInput placeholder="Enter HL Integrtaion key" formControlName="hl_integration_key" id="hl_integration_key" />
                                    <mat-error *ngIf="merchantForm.get('account_details.hl_integration_key')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <!-- only on edit -->
                                <mat-form-field *ngIf="isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Zoho customer ID</mat-label>
                                    <input matInput placeholder="Enter zoho customer ID" formControlName="zoho_customer_id" id="zoho_customer_id" />
                                    <mat-error *ngIf="merchantForm.get('account_details.zoho_customer_id')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <!-- only on edit -->
                                <mat-form-field *ngIf="isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Zoho subscription customer ID</mat-label>
                                    <input matInput placeholder="Enter Zoho Subscription Customer ID" formControlName="zoho_subscription_customer_id" id="zoho_subscription_customer_id" />
                                    <mat-error *ngIf="merchantForm.get('account_details.zoho_subscription_customer_id')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <!-- Account manager -->
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Select account manager</mat-label>
                                    <mat-select formControlName="admin_id">
                                        <mat-option [value]="am.id" *ngFor="let am of accountManagerList">{{am.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('account_details.admin_id')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <!-- reasion drop down -->
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Tell us why you're signing up with Thrive?</mat-label>
                                    <mat-select formControlName="joining_reason">
                                        <mat-option [value]="reason" *ngFor="let reason of join_reason">{{reason}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field *ngIf="isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 disabled">
                                    <mat-label>Authentication Token</mat-label>
                                    <input matInput placeholder="Authentication Token" formControlName="authentication_token"  id="authentication_token"/>
                                </mat-form-field>
                                <!-- onboarding source -->
                                <mat-form-field *ngIf="isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 disabled">
                                    <mat-label>Onboarding Source</mat-label>
                                    <input matInput placeholder="Onboarding Source" formControlName="onboarding_source"  id="onboarding_source"/>
                                </mat-form-field>
                                <!-- Outlet Counts -->
                                <mat-form-field *ngIf="merchantForm.get('account_details.outlets') && isEdit" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 disabled">
                                    <mat-label>Outlets</mat-label>
                                    <input matInput placeholder="Outlet" formControlName="outlets" id="outlets" />
                                    <mat-error *ngIf="merchantForm.get('account_details.outlets')?.invalid">Required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <!-- Restaurant Information -->
                    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" *ngIf="showStep[merchantForm.get('role')?.value][2]">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="basetitle">Restaurant information</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="expansion-body" formGroupName="restaurant_info">
                            <div class="flex-form">
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Restaurant type</mat-label>
                                    <mat-select formControlName="restaurant_type">
                                        <mat-option *ngFor="let type of restaurantTypes" [value]="type.id">{{ type.name }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('restaurant_info.restaurant_type')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Cuisines</mat-label>
                                    <mat-select formControlName="cuisines" multiple>
                                        <mat-option>
                                            <ngx-mat-select-search
                                                                   placeholderLabel="Search"
                                                                   noEntriesFoundLabel="Cuisine not found"
                                                                   ngDefaultControl
                                                                   [formControl]="searchCuisine">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let type of cuisines | datafilter : searchCuisine.value :['name']" [value]="type.id">{{ type.name }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('restaurant_info.cuisines')?.invalid">Required</mat-error>
                                </mat-form-field>
                                <div>
                                    <p class="basetitle normal mb-10">Upload logo (1:1)</p>
                                    <image-upload [aspectRatio]="'1:1'" [imageUrl]="merchantForm.get('restaurant_info.logo')?.value" (uploadImg)="onImageUpload($event, 'logo')"></image-upload>
                                </div>
                                <div>
                                    <p class="basetitle normal mb-10">Upload cover image (4:3)</p>
                                    <image-upload [aspectRatio]="'4:3'" [imageUrl]="merchantForm.get('restaurant_info.background_image')?.value" (uploadImg)="onImageUpload($event, 'background_image')"></image-upload>
                                </div>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                    <mat-label>Average cost for 2</mat-label>
                                    <input matInput placeholder="Enter Average Cost for 2" formControlName="average_spend" />
                                    <mat-error *ngIf="merchantForm.get('restaurant_info.average_spend')?.errors?.required">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('restaurant_info.average_spend')?.errors?.pattern">Digits only</mat-error>
                                </mat-form-field>
                            </div>
                            <ng-container *ngIf="merchantForm.get('role').value === 'Merchant'">
                                <!-- **********  FSSAI  *********** -->
                                <div class="flex-form center">
                                    <p class="basetitle normal">Do you have a valid FSSAI registration / license?<sup>*</sup></p>
                                    <div>
                                        <mat-radio-group formControlName="fssai_licensed" (change)="setOrRemoveValidationOfFssai($event.value)" color="primary">
                                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                                            <mat-radio-button [value]="false">No</mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_licensed')?.errors?.required">Please make a Choice!</mat-error>
                                    </div>
                                </div>
                                <!-- If FSSAI is true -->
                                <div class="flex-form mt-20" *ngIf="merchantForm.get('restaurant_info.fssai_licensed').value">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>FSSAI License Number</mat-label>
                                        <input matInput placeholder="Enter FSSAI License Number" formControlName="fssai_license_no" />
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_license_no').hasError('required')">
                                            Required
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_license_no').hasError('pattern')">
                                            Digits only.
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_license_no').hasError('minlength')">
                                            14 digits only
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_license_no').hasError('maxlength')">
                                            14 digits only
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_license_no').hasError('invalid_fssai')">{{errorMessage}}</mat-error>
                                    </mat-form-field>
                                    <div>
                                        <button [disabled]="merchantForm.get('restaurant_info.fssai_license_no').invalid" class="btn-thrive_primary w-100" mat-button *ngIf="!merchantForm.get('restaurant_info.is_fssai_verified').value" (click)="verifyFSSAI()">
                                            <span>Verify FSSAI Number</span>
                                        </button>
                                        <p class="verified-success" *ngIf="merchantForm.get('restaurant_info.is_fssai_verified').value">
                                            <mat-icon>check_circle</mat-icon>
                                            <span>FSSAI Number Verified</span>
                                        </p>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.is_fssai_verified').hasError('required') && merchantForm.get('restaurant_info.is_fssai_verified').touched">Verification is required</mat-error>
                                    </div>
                                    <mat-form-field *ngIf="merchantForm.get('restaurant_info.fssai_licensed').value===true" appearance="outline" subscriptSizing="dynamic" floatLabel="never">
                                        <mat-label>Fssai expiry date</mat-label>
                                        <input matInput placeholder="Fssai expiry date (DD/MM/YYYY)" formControlName="fssai_expiry_date" [matDatepicker]="expiry_date" />
                                        <mat-datepicker-toggle [disableRipple]="true" matIconSuffix [for]="expiry_date"></mat-datepicker-toggle>
                                        <mat-datepicker #expiry_date></mat-datepicker>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_expiry_date').errors && merchantForm.get('restaurant_info.fssai_expiry_date').dirty"> Required </mat-error>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="merchantForm.get('restaurant_info.fssai_licensed').value===true">
                                    <p class="basetitle normal mb-10 mt-10">FSSAI Certificate</p>
                                    <app-pdf-upload  [pdfUrl]="merchantForm.get('restaurant_info.fssai_certificate')?.value" (uploadPdf)="onUploadCertificate($event, 'fssai_certificate')" ></app-pdf-upload>
                                </div>

                                <!-- If FSSAI is false -->
                                <div class="flex-form mt-20" *ngIf="merchantForm.get('restaurant_info.fssai_licensed').value===false">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>FSSAI Application Number</mat-label>
                                        <input matInput placeholder="Enter FSSAI Application Number" formControlName="fssai_application_no" />
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_application_no').hasError('required') ">
                                            Required
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_application_no').hasError('pattern') ">
                                            Digits only.
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_application_no').hasError('minlength')  ">
                                            17 digits only
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_application_no').hasError('maxlength') ">
                                            17 digits only
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>FSSAI Date of Application</mat-label>
                                        <input matInput [matDatepicker]="fssai_application_date_picker" formControlName="fssai_application_date" readonly />
                                        <mat-datepicker-toggle matIconSuffix [for]="fssai_application_date_picker"></mat-datepicker-toggle>
                                        <mat-datepicker #fssai_application_date_picker></mat-datepicker>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.fssai_application_date')?.invalid">FSSAI Application Date is Required</mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- **********  FSSAI  END *********** -->
                                <!-- **********  GST  *********** -->
                                <div class="flex-form center mt-20">
                                    <p class="basetitle normal">Is your restaurant GST registered?<sup>*</sup></p>
                                    <div>
                                        <mat-radio-group formControlName="gst_registered" color="primary" (change)="setOrRemoveValidationOfGST($event.value)">
                                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                                            <mat-radio-button [value]="false">No</mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.gst_registered').errors && merchantForm.get('restaurant_info.gst_registered').dirty">Please make a Choice!</mat-error>
                                    </div>
                                </div>
                                <!-- If GST is true -->
                                <div class="flex-form mt-20" *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===true">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>GST Number</mat-label>
                                        <input matInput placeholder="Enter GST Number" formControlName="gst_number" (input)="clearGstInput()" />
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.gst_number').hasError('required')">
                                            Required
                                        </mat-error>
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.gst_number').hasError('pattern')">
                                            Enter valid GST number.
                                        </mat-error>
                                    </mat-form-field>
                                    <button [disabled]="merchantForm?.get('restaurant_info.gst_number').invalid" *ngIf="!gstInfo && !gstInfo?.message" class="btn-thrive_primary w-100" (click)="verifyGSTNumber()">
                                        Verify GST Number
                                    </button>
                                    <p class="validate-gst failure-gst" *ngIf="gstInfo && !gstInfo.is_valid && gstInfo?.message">
                                        <mat-icon>cancel</mat-icon>
                                        <span>{{ gstInfo?.message }}</span>
                                    </p>
                                    <p class="validate-gst success-gst" *ngIf="gstInfo && gstInfo.is_valid && gstInfo?.message">
                                        <mat-icon>check_circle</mat-icon>
                                        <span>{{ gstInfo?.message }}</span>
                                    </p>
                                </div>
                                <div *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===true">
                                    <p class="basetitle normal mb-10 mt-10">GST Certificate</p>
                                    <app-pdf-upload  [pdfUrl]="merchantForm.get('restaurant_info.gst_certificate')?.value" (uploadPdf)="onUploadCertificate($event, 'gst_certificate')" ></app-pdf-upload>
                                </div>
                                <!-- If GST is false -->
                                <ng-container *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===false">
                                    <mat-checkbox class="mt-20" color="primary" formControlName="declaration">
                                        I / We hereby declare that we are not required to obtain registration under GST in accordance with Section 22 of the CGST Act, 2017 as we are below the threshold limit for registration as prescribed under the GST law
                                        in our State.
                                    </mat-checkbox>
                                </ng-container>
                                <div class="flex-form mt-20" *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===false">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>PAN Number</mat-label>
                                        <input matInput placeholder="Enter PAN number" formControlName="pan_number" (input)="clearPanInput()" />
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.pan_number').hasError('required')">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.pan_number').hasError('pattern')">Enter valid PAN number.</mat-error>
                                    </mat-form-field>
                                    <button [disabled]="merchantForm?.get('restaurant_info.pan_number').invalid" class="btn-thrive_primary w-100" *ngIf="!panInfo && !panInfo?.message" (click)="verifyPANNumber()">Verify PAN Number</button>
                                    <p class="validate-gst failure-gst" *ngIf="panInfo && !panInfo.is_valid && panInfo?.message">
                                        <mat-icon>cancel</mat-icon>
                                        <span>{{ panInfo?.message }}</span>
                                    </p>
                                    <p class="validate-gst success-gst" *ngIf="panInfo && panInfo.is_valid && panInfo?.message">
                                        <mat-icon>check_circle</mat-icon>
                                        <span>{{ panInfo?.message }}</span>
                                    </p>
                                </div>
                                <div class="flex-form mt-20" *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===false">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 disabled-block">
                                        <mat-label>Registeration Name</mat-label>
                                        <input readonly matInput placeholder="Enter registeration name" formControlName="legal_entity_name" [disabled]="true" />
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.legal_entity_name')?.invalid">Required</mat-error>
                                    </mat-form-field>

                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>Company Type</mat-label>
                                        <mat-select placeholder="Enter Company Type" formControlName="gst_company_type">
                                            <mat-option *ngFor="let type of companyTypesList" [value]="type.id">{{ type.name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.gst_company_type').invalid">Company Type is Required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex-form mt-20" *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===true">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100  disabled-block">
                                        <mat-label>GST Company Name</mat-label>
                                        <input readonly matInput placeholder="Enter GST Company Name" formControlName="legal_entity_name" [disabled]="true" />
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.legal_entity_name')?.invalid">Required</mat-error>
                                    </mat-form-field>
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100  disabled-block">
                                        <mat-label>GST Company Type</mat-label>
                                        <input readonly matInput placeholder="Enter GST Company Type" formControlName="gst_company_type" [disabled]="true" />
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.gst_company_type')?.invalid">Required</mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- If GST is true -->
                                <div class="flex-form mt-20" *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===true">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100  disabled-block">
                                        <mat-label>GST Registration Date</mat-label>
                                        <input matInput [matDatepicker]="gst_registered_date_picker" formControlName="gst_registered_date" readonly [disabled]="true" />
                                        <mat-datepicker-toggle matIconSuffix [for]="gst_registered_date_picker"></mat-datepicker-toggle>
                                        <mat-datepicker #gst_registered_date_picker></mat-datepicker>
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.gst_registered_date')?.invalid">Required</mat-error>
                                    </mat-form-field>
                                    <!-- <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>GST registration email</mat-label>
                                        <input matInput placeholder="Enter GST Registration email" formControlName="gst_registered_email" />
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.gst_registered_email')?.errors?.required">Required</mat-error>
                                        <mat-error *ngIf="merchantForm?.get('restaurant_info.gst_registered_email')?.errors?.email">Invalid format</mat-error>
                                    </mat-form-field> -->
                                </div>
                                <!-- If GST is true -->
                                <div class="flex-form center mt-20" *ngIf="merchantForm?.get('restaurant_info.gst_registered')?.value===true">
                                    <p class="basetitle normal">Is your restaurant part of a hotel?<sup>*</sup></p>
                                    <div>
                                        <mat-radio-group formControlName="part_of_hotel" color="primary">
                                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                                            <mat-radio-button [value]="false">No</mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error *ngIf="merchantForm.get('restaurant_info.part_of_hotel')?.errors?.required">Please make a Choice!</mat-error>
                                    </div>
                                </div>
                                <!-- GST -->
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                    <!-- Location and Contact Details  -->
                    <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" *ngIf="showStep[merchantForm.get('role')?.value][3]">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="basetitle">Location and contact details</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="expansion-body" formGroupName="contact_details">
                            <ng-container *ngIf="merchantForm.get('role')?.value === 'Merchant'">
                                <div class="position-relative">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                        <mat-label>Address</mat-label>
                                        <input type="text" #search_address [type]="'text'" [placeholder]="'Search address'" [formControl]="searchKeyword" matInput placeholder="Search">
                                        <mat-hint>OR place the pin accurately at your outlet’s location on the map. This will help customers and third-party delivery partners to locate your store.</mat-hint>
                                        <mat-error *ngIf="merchantForm.get('contact_details.search_address').errors"> Please search to choose a valid address </mat-error>
                                    </mat-form-field>
                                    <div class="search-list" *ngIf="searchKeyword?.value?.length && searchResults?.length" clickOutside (clickOutside)="closeSearchResults()">
                                        <div class="search-option" *ngFor="let address of searchResults; trackBy: trackByPlaceId" (click)="chooseSearchedAdd(address)">
                                            <mat-icon class="marker">location_on</mat-icon>
                                            {{ address?.description }}
                                        </div>
                                        <div class="search-footer pt-10">
                                            <img class="logo-google" src="../../../assets/powered_by_google_on_white.png" alt="google_logo" />
                                        </div>
                                    </div>
                                </div>
                                <div class="location">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>Latitude</mat-label>
                                        <input matInput placeholder="Enter Pincode" formControlName="lat" />
                                        <mat-error *ngIf="merchantForm.get('contact_details.lat')?.hasError('required')">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('contact_details.lat').hasError('pattern')">Enter valid latitude</mat-error>
                                    </mat-form-field>
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>Longitude</mat-label>
                                        <input matInput placeholder="Enter longitude" formControlName="lon" />
                                        <mat-error *ngIf="merchantForm.get('contact_details.lon')?.errors?.required">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('contact_details.lon').hasError('pattern')">Enter valid longitude</mat-error>
                                    </mat-form-field>
                                    <button class="btn-thrive_primary" mat-button (click)="getFullAddress()">Search</button>
                                </div>
                                <div class="map" #mapContainer></div>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-20 mb-20">
                                    <mat-label>Full address</mat-label>
                                    <textarea  pattern="[a-zA-Z0-9\s!@#$*_|:;/.,-?+=&[\]()`~&apos;&quot;]*" matInput placeholder="Enter full address" formControlName="outlet_address"></textarea>
                                    <mat-error *ngIf="merchantForm.get('contact_details.outlet_address').hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('contact_details.outlet_address').hasError('pattern')">Please add address in English language. Only alphabets, numbers and a few symbols (, - _/#) allowed.</mat-error>
                                </mat-form-field>
                                <div class="flex-form">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                        <mat-label>Locality</mat-label>
                                        <input matInput  pattern="[a-zA-Z0-9\s!@#$*_|:;/.,-?+=&[\]()`~&apos;&quot;]*" placeholder="Enter locality" formControlName="location" />
                                        <mat-error *ngIf="merchantForm.get('contact_details.location')?.hasError('required')">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('contact_details.location')?.hasError('pattern')">Please add address in English language. Only alphabets, numbers and a few symbols (, - _/#) allowed.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                        <mat-label>Pincode</mat-label>
                                        <input matInput placeholder="Enter Pincode" formControlName="pincode" />
                                        <mat-error *ngIf="merchantForm.get('contact_details.pincode')?.hasError('required')">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('contact_details.pincode')?.hasError('pattern')">Invalid format</mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <div class="flex-form">
                                <mat-form-field  *ngIf="merchantForm.get('role')?.value === 'Merchant'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                    <mat-label>Outlet number</mat-label>
                                    <input matInput placeholder="Enter outlet number" formControlName="contact_number" />
                                    <mat-error *ngIf="merchantForm.get('contact_details.contact_number')?.errors?.required">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('contact_details.contact_number')?.errors?.maxlength">Maximum 10 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('contact_details.contact_number')?.errors?.minlength">Minimum 10 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('contact_details.contact_number')?.errors?.pattern">Digits only</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                    <mat-label>Outlet email id</mat-label>
                                    <input matInput placeholder="Enter outlet email id" formControlName="email" />
                                    <mat-error *ngIf="merchantForm.get('contact_details.email')?.hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('contact_details.email')?.hasError('pattern')">Invalid format</mat-error>
                                </mat-form-field>
                            </div>
                            <ng-container *ngIf="merchantForm.get('role')?.value === 'Merchant'">
                                <ng-container *ngIf="!showAdvanceSettings">
                                    <basic-open-hours [basic_default_time_slots]="preDefineSlots" (submitBasic)="getOpenHours($event)"></basic-open-hours>
                                    <p class="basetitle normal">
                                        Want different timeslots on different days? Apply
                                        <span class="link" (click)="toggleAdvanceSettings()">advanced settings.</span>
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="showAdvanceSettings">
                                    <advance-open-hours [default_time_slots]="preDefineSlots" (submit)="getOpenHours($event)"></advance-open-hours>
                                    <p class="basetitle normal">
                                        Go back to
                                        <span class="link" (click)="toggleAdvanceSettings()">basic settings.</span>
                                    </p>
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                    <!-- Communication Details -->
                    <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" *ngIf="showStep[merchantForm.get('role')?.value][4]">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="basetitle">Communication details</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="expansion-body" formGroupName="miscellaneous">
                            <p class="subbase mb-10">SMS registeration details</p>
                            <div class="flex-form">
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                    <mat-label>Entity ID</mat-label>
                                    <input matInput placeholder="Enter Entity ID" formControlName="solutions_entity_id" />
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.solutions_entity_id')?.errors?.maxlength">Maximum 19 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.solutions_entity_id')?.errors?.minlength">Minimum 19 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.solutions_entity_id')?.errors?.pattern">Digits only</mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Sender ID (Promotional)</mat-label>
                                    <input matInput placeholder="Enter Sender ID (Promotional)" formControlName="promotional_sender_id" />
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.promotional_sender_id')?.errors?.pattern">Alphabets and numbers only</mat-error>
                                </mat-form-field> -->
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Sender ID (Transactional)</mat-label>
                                    <input matInput placeholder="Enter Sender ID (Transactional)" formControlName="sender_id" />
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.sender_id')?.errors?.pattern">Alphabets and numbers only</mat-error>
                                </mat-form-field>
                            </div>
                            <p class="subbase mt-20 mb-10">Whatsapp registeration details</p>
                            <div class="flex-form mb-20">
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>WABA ID</mat-label>
                                    <input matInput placeholder="Enter WABA ID" formControlName="waba_id" autocomplete="off"/>
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.waba_id')?.errors?.pattern">Digits only</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Mobile Number (WABA)</mat-label>
                                    <input matInput placeholder="Enter Mobile Number (WABA)" formControlName="mobile_waba" autocomplete="off"/>
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.mobile_waba')?.errors?.maxlength">Maximum 10 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.mobile_waba')?.errors?.minlength">Minimum 10 digits</mat-error>
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.mobile_waba')?.errors?.pattern">Digits only</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Whatsapp token</mat-label>
                                    <input matInput placeholder="Enter whatsapp token" formControlName="token" autocomplete="off"/>
                                    <mat-error *ngIf="merchantForm.get('miscellaneous.token')?.errors?.pattern">Alphabets and numbers only</mat-error>
                                </mat-form-field>
                            </div>
                            <!-- only on edit -->
                            <div class="mb-20" *ngIf="isEdit">
                                <mat-slide-toggle color="primary" formControlName="is_dynamic_otp_for_redemption">Dynamic otp for redemption (Applies only on offline CRM )</mat-slide-toggle>
                            </div>
                            <!-- only on edit -->
                            <div class="mb-20" *ngIf="isEdit && merchantDetails?.miscellaneous?.is_loyalty_model">
                                <mat-slide-toggle color="primary" formControlName="is_custom_messages">Activate Custom Messages (Applies only on offline CRM )</mat-slide-toggle>
                                <div class="mt-30" *ngIf="merchantForm.get('miscellaneous.is_custom_messages')?.value">
                                    <app-custom-message [merchantDetails]="merchantDetails" [merchantForm]="merchantForm"></app-custom-message>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <!-- Pricing and ordering modes -->
                    <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" *ngIf="showStep[merchantForm.get('role')?.value][5]">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="basetitle">Pricing and ordering modes</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="expansion-body" formGroupName="pricing">
                            <ng-container *ngIf="merchantForm.get('account_details.platform').value === 0 || merchantForm.get('account_details.platform').value === 2 || merchantForm.get('account_details.platform').value === 5 || merchantForm.get('account_details.platform').value === 7">
                                <div class="basetitle normal mb-20">Direct</div>
                                <div class="flex-form mb-30">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                        <mat-label>Commission</mat-label>
                                        <input type="text" matInput placeholder="Enter Commission" formControlName="microsite_fee" />
                                        <span matTextSuffix>%</span>
                                        <mat-error *ngIf="merchantForm.get('pricing.microsite_fee')?.errors?.required">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('pricing.microsite_fee')?.errors?.pattern">Accepted values are positive numbers upto 2 decimal points.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                                        <mat-label>PG Plan</mat-label>
                                        <mat-select formControlName="payment_gateway_plan_id">
                                            <mat-option *ngFor="let plan of plans" [value]="plan.id">{{ plan.name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="merchantForm.get('pricing.payment_gateway_plan_id')?.invalid">Plan is Required</mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            
                            <ng-container *ngIf="merchantForm.get('account_details.platform').value === 1 || merchantForm.get('account_details.platform').value === 2 || merchantForm.get('account_details.platform').value === 6 || merchantForm.get('account_details.platform').value === 7">
                                <div class="basetitle normal mb-20">Consumer</div>
                                <mat-slide-toggle color="primary" formControlName="thrive_exclusive" class="mb-20">Thrive Promise</mat-slide-toggle>
                                <div class="flex-form mb-30">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>Pricing for New Customers</mat-label>
                                        <input type="text" matInput placeholder="Enter New Customer Pricing" formControlName="consumer_app_new_user_fee" />
                                        <span matTextSuffix>%</span>
                                        <mat-error *ngIf="merchantForm.get('pricing.consumer_app_new_user_fee')?.errors?.required">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('pricing.consumer_app_new_user_fee')?.errors?.pattern">Accepted values are positive numbers upto 2 decimal points.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>Pricing for Repeat Customers</mat-label>
                                        <input type="text" matInput placeholder="Enter Repeat Customer Pricing" formControlName="consumer_app_repeat_user_fee" />
                                        <span matTextSuffix>%</span>
                                        <mat-error *ngIf="merchantForm.get('pricing.consumer_app_repeat_user_fee')?.errors?.required">Required</mat-error>
                                        <mat-error *ngIf="merchantForm.get('pricing.consumer_app_repeat_user_fee')?.errors?.pattern">Accepted values are positive numbers upto 2 decimal points.</mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="merchantForm.get('account_details.platform').value === 4 || merchantForm.get('account_details.platform').value === 5 || merchantForm.get('account_details.platform').value === 7 || merchantForm.get('account_details.platform').value === 6">
                                <div class="basetitle normal mb-20">ONDC</div>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-30">
                                    <mat-label>Set a maximum buyer app commission (%)</mat-label>
                                    <input type="text" matInput placeholder="Enter commission" formControlName="ondc_max_fee" />
                                    <span matTextSuffix>%</span>
                                    <mat-error *ngIf="merchantForm.get('pricing.ondc_max_fee')?.errors?.required">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('pricing.ondc_max_fee')?.errors?.pattern">Accepted values are positive numbers upto 2 decimal points.</mat-error>
                                </mat-form-field>  
                            </ng-container>

                            <div formGroupName="availability">
                                <mat-checkbox class="block" color="primary" id="delivery" formControlName="delivery">
                                    <span class="radio-label"> Delivery</span>
                                </mat-checkbox>
                                <mat-checkbox class="block" color="primary" *ngIf="merchantForm.get('account_details.platform').value !== 4" id="take_away" formControlName="take_away">
                                    <span class="radio-label">Pickup</span>
                                </mat-checkbox>
                                <mat-checkbox class="block" color="primary" *ngIf="merchantForm.get('account_details.platform').value !== 1 && merchantForm.get('account_details.platform').value !== 4" id="dine_in" formControlName="dine_in">
                                    <span class="radio-label">Dine in</span>
                                </mat-checkbox>
                                <mat-error *ngIf="merchantForm.get('pricing.availability')?.invalid">
                                    Ordering should be available on at least one platform
                                </mat-error>
                            </div>
                        </div>
                    
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" *ngIf="showStep[merchantForm.get('role')?.value][6]">
                        <mat-expansion-panel-header >
                            <mat-panel-title class="basetitle">Bank details</mat-panel-title>
                            <div class="link mr-20 basetitle" (click)="bankDetailsSkip ? addbankDetails($event) : skipBanckDetails($event)"> {{bankDetailsSkip?'Add':'Skip'}}</div>
                        </mat-expansion-panel-header>
                        <div class="expansion-body" *ngIf="!bankDetailsSkip" formGroupName="bank_account_details">
                            <div class="flex-form mt-20">
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Account number</mat-label>
                                    <input matInput type="password" placeholder="Bank account number" (input)="unVerify()" (keypress)="numberOnly($event)" onpaste="return false;" ondrop="return false;" autocomplete="off" formControlName="account_number" />
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.account_number').hasError('required')">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Re-Enter Account Number</mat-label>
                                    <input matInput placeholder="Confirm account number" (input)="unVerify()" (keypress)="numberOnly($event)" onpaste="return false;" ondrop="return false;" autocomplete="off" formControlName="confirm_account_number" />
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.confirm_account_number').hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.confirm_account_number').hasError('notMatch')">Account number does not match.</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>IFSC Code</mat-label>
                                    <input matInput placeholder="IFSC Code" formControlName="ifsc_code" (input)="verifyIFSCCode($event.target.value)" />
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.ifsc_code').hasError('required')">Required</mat-error>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.ifsc_code').hasError('pattern')">Please enter your 11 digit IFSC code.</mat-error>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.ifsc_code').hasError('incorrect')">Please enter valid IFSC code.</mat-error>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.ifsc_code').hasError('notvalid')">{{invalidBankDetailsMessage}}</mat-error>
                                </mat-form-field>
                                <div>
                                    <button class="btn-thrive_primary w-100" [disabled]="merchantForm.get('bank_account_details.ifsc_code').invalid" [disabled]="merchantForm.get('bank_account_details.account_number').invalid" (click)="verifyBankDetails()" *ngIf="!merchantForm.get('bank_account_details.is_verified').value" >
                                        Verify Bank Details
                                    </button>
                                    <p class="verified-success"  *ngIf="merchantForm.get('bank_account_details.is_verified').value">
                                        <mat-icon>check_circle</mat-icon>
                                        <span>Bank details verified</span>
                                    </p>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.is_verified').hasError('required') && merchantForm.get('bank_account_details.is_verified').touched">Please verify first.</mat-error>
                                </div>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Account holder name</mat-label>
                                    <input matInput placeholder="Account holder name"  formControlName="account_holder_name" />
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.account_holder_name').hasError('required')">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                    <mat-label>Business type</mat-label>
                                    <mat-select formControlName="business_type">
                                        <mat-option *ngFor="let type of businessTypeList" [value]="type.id">{{ type.name }}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.business_type')?.invalid">Required.</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="disabled w-100">
                                    <mat-label>Bank name</mat-label>
                                    <input matInput placeholder="Bank name"  formControlName="bank_name" />
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.bank_name').hasError('required')">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="disabled w-100">
                                    <mat-label>Branch</mat-label>
                                    <input matInput placeholder="Branch"  formControlName="branch" readonly/>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.branch').hasError('required')">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="disabled w-100">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City"  formControlName="city" readonly/>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.city').hasError('required')">Required</mat-error>
                                </mat-form-field>
                                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="disabled w-100">
                                    <mat-label>State</mat-label>
                                    <input matInput placeholder="State"  formControlName="state" readonly/>
                                    <mat-error *ngIf="merchantForm.get('bank_account_details.state').hasError('required')">Required</mat-error>
                                </mat-form-field> 
                                <!-- <button mat-button class="btn-thrive_primary" (click)="skipBanckDetails()"> {{bankDetailsSkip?'Add bank details':'skip bank details'}}</button> -->
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel  [expanded]="step === 7" (opened)="setStep(7)" *ngIf="showStep[merchantForm.get('role')?.value][7]">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="basetitle">Merchant Agreement</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="expansion-body">
                            <div>
                                <p class="basetitle normal mb-10">Upload Contract</p>
                                <image-upload [accept]="'.pdf'" [pdf]="true" [imageUrl]="merchantForm.get('contract_copy')?.value" (uploadImg)="onImageUpload($event, 'contract')"></image-upload>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </form>
        </div>
        <div class="action-footer">
            <div class="footer-container">
                <button class="btn-thrive_primary" 
                accessControl
                moduleType="merchant_master" 
                [disabled]="inProccess" 
                [ngClass]="{'disabled-block':inProccess}" 
                mat-button  (click)="submitForm()">
                    <mat-icon>add</mat-icon>
                    <span>{{ editId === 'new' ? 'Create' : 'Update' }}</span>
                </button>
            </div>
        </div>
    </ng-container>
</div>